import React from "react";
import "../App.css";

function Navbar() {
  return (
    <>
      <div className="nav">
        <h5 className="text-center">News Uploader</h5>
      </div>
    </>
  );
}

export default Navbar;
