import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useForm } from "react-hook-form";
import "../App.css";
function Newsform() {
  const [data, setData] = useState([]);
  const [indianNews, setindianNews] = useState([]);
  // const [formData, setFormData] = useState({});
  const [Name, setName] = useState("");
  const [Description, setDescrition] = useState("");
  const [File, setFile] = useState();
  const [date, setDate] = useState();

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({ ...formData, [name]: value, });

  // };
  const handleName = (e) => {
    setName(e.target.value);
    console.log(Name);
  };

  const handleDescription = (e) => {
    setDescrition(e.target.value);
    console.log(Description);
  };

  const handleDate = (e) => {
    setDate(e.target.value);
    console.log(date);
  };

  const handleFile = async (e) => {
    await setFile(e.target.files[0]);
    console.log(File);
  };

  const formData = new FormData();
  formData.append("title", Name);
  formData.append("description", Description);
  formData.append("newDate", date);
  formData.append("file", File);

  const handleIndiannews = () => {
    try {
      let title = document.getElementById("name");
      let description = document.getElementById("description");
      let imageUrl = document.getElementById("imageUrl");
      let date = document.getElementById("date");
      if (!(title.value && description.value && imageUrl.value && date.value)) {
        toast.error("Fill the form", {
          autoClose: 1000,
          pauseOnFocusLoss: false,
        });
      } else {
        console.log(formData);
        axios
          .post("https://news-ir1m.onrender.com/indianpost", formData)
          .then((resp) => {
            console.log(resp.data);
            getIndiannews();
          });

        toast.success("Indian News Succssfully Saved", {
          autoClose: 1000,
          pauseOnFocusLoss: false,
        });
        document.getElementById("name").value = "";
        document.getElementById("description").value = "";
        document.getElementById("imageUrl").value = "";
        document.getElementById("date").value = "";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      let title = document.getElementById("name");
      let description = document.getElementById("description");
      let imageUrl = document.getElementById("imageUrl");
      let date = document.getElementById("date");

      if (!(title.value && description.value && imageUrl.value && date.value)) {
        toast.error("Fill the form", {
          autoClose: 1000,
          pauseOnFocusLoss: false,
        });
      } else {
        await axios
          .post("https://news-ir1m.onrender.com/formpost", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            console.log(resp.data.message);
          });

        toast.success("News Succssfully Saved", {
          autoClose: 1000,
          pauseOnFocusLoss: false,
        });
        document.getElementById("name").value = "";
        document.getElementById("description").value = "";
        document.getElementById("imageUrl").value = "";
        document.getElementById("date").value = "";
        await getData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleIndianDelete = async (id) => {
    try {
      axios
        .delete(`https://news-ir1m.onrender.com/indianNewsdelete/${id}`)
        .then((resp) => {
          if (resp.data.message == "Deleted") {
            toast.success("Deleted Successfully", {
              autoClose: 1000,
              position: "top-center",
            });
            getIndiannews();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log(id);
      axios
        .delete(`https://news-ir1m.onrender.com/formdelete/${id}`)
        .then((resp) => {
          if (resp.data.message == "Deleted") {
            toast.success("Deleted Successfully", {
              autoClose: 1000,
              position: "top-center",
            });
            getData();
          }
        });
    } catch (error) {
      console.log(error);
    }
    await getData();
  };

  function getData() {
    axios.get("https://news-ir1m.onrender.com/formget").then(async (resp) => {
      await setData(resp.data);
    });
  }

  function getIndiannews() {
    axios
      .get("https://news-ir1m.onrender.com/indianNews")
      .then(async (resp) => {
        await setindianNews(resp.data);
      });
  }
  useEffect(() => {
    getData();
    getIndiannews();
  }, []);

  return (
    <>
      <div className="container">
        <form onSubmit={onSubmit} method="POST" enctype="multipart/form-data">
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              News Title
            </label>
            <input
              type="text"
              // {...register("title")}
              className="form-control"
              name="name"
              onChange={handleName}
              id="name"
              placeholder="name@example.com"
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              News Description
            </label>
            <textarea
              // {...register("description")}
              className="form-control"
              name="description"
              onChange={handleDescription}
              id="description"
              rows="3"
            ></textarea>
          </div>

          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              News Image
            </label>
            <input
              type="file"
              // {...register("imageUrl")}
              placeholder="Image Url"
              id="imageUrl"
              name="file"
              onChange={handleFile}
              className="form-control"
            />
          </div>

          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              News date
            </label>
            <input
              type="text"
              // {...register("newDate")}
              placeholder="published date"
              id="date"
              onChange={handleDate}
              name="newDate"
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <input
              type="submit"
              value="Save International News"
              className="btn btn-primary mx-2"
            />
            <input
              type="button"
              onClick={() => handleIndiannews()}
              value="Save Indian News"
              className="btn btn-secondary"
            />
          </div>
        </form>
      </div>
      <div className="showData">
        <div className="listData">
          {data ? (
            data.map((element) => {
              return (
                <>
                  <div className="container">
                    <div className="displayData my-3">
                      <img
                        src={element.imageurl}
                        alt="image"
                        className="displayImage"
                      />

                      <h3 className="text-center my-2">{element.name}</h3>
                      <hr />
                      <h6 className="description">{element.description}</h6>
                      <h6 className="date">Published: {element.date}</h6>
                      <div className="deletebtn">
                        <button
                          onClick={() => handleDelete(element._id)}
                          className="btn btn-danger"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <h3>Loading</h3>
          )}
        </div>

        <div className="listData">
          {indianNews ? (
            indianNews.map((element) => {
              return (
                <>
                  <div className="container">
                    <div className="displayData my-3">
                      <img
                        src={element.imageurl}
                        alt="image"
                        className="displayImage"
                      />

                      <h3 className="text-center my-2">{element.name}</h3>
                      <hr />
                      <h6 className="description">{element.description}</h6>
                      <h6 className="date">Published: {element.date}</h6>
                      <div className="deletebtn">
                        <button
                          onClick={() => handleIndianDelete(element._id)}
                          className="btn btn-danger"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <h3>Loading</h3>
          )}
        </div>
      </div>
    </>
  );
}

export default Newsform;
